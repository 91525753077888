import axios from 'axios';

const URL = 'http://localhost:3000';

class assetService {
  fetchAll() {
    return axios.get(`${URL}/assets`);
  }
}

export default new assetService();
