export default {
  getWithdrawInput(state) {
    return state.withdrawInput;
  },
  getAssets(state) {
    return state.assets;
  },
  getToken(state) {
    return state.token;
  },
  getTransaction(state) {
    return state.transaction;
  }
};
