import axios from 'axios';

const URL = 'http://localhost:3000';

class WithdrawService {
  withdraw(qparams) {
    return axios.get(`${URL}/sep24/transactions/withdraw/webapp${qparams}`);
  }

  confirm(payload, qparams, token = '') {
    const params = new URLSearchParams({ ...qparams, ...payload });

    return axios.post(
      `${URL}/sep24/transactions/withdraw/webapp?${params}`,

      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  }

  moreInfo(qparams, token = '') {
    const params = new URLSearchParams(qparams);

    return axios.get(`${URL}/sep24/transaction/more_info?${params}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}

export default new WithdrawService();
