<template>
  <div>Deposit Asset Selection Page</div>
</template>

<script>
export default {
  name: 'DepositAssetSelectionPage'
};
</script>

<style scoped></style>
