import { createStore } from 'vuex';

import rootActionsModule from './actions';
import rootMutationsModule from './mutations';
import rootGettersModule from './getters';

const store = createStore({
  state() {
    return {
      withdrawInput: {
        amount: '',
        transactionType: '',
        bankAccount: '',
        bankName: '',
        asset: ''
      },
      depositInput: {
        asset: '',
        amount: ''
      },
      assets: [],
      token: '',
      transaction: {}
    };
  },
  actions: rootActionsModule,
  mutations: rootMutationsModule,
  getters: rootGettersModule
});

export default store;
