<template>
  <div class="container mt-5">
    <div class="text-center mb-5">
      <a href="https://transcryptglobal.com/" target="_blank">
        <img alt="Match" src="@/assets/logo.png" class="img-fluid" />
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <form class="w-50" @submit.prevent="formSubmitHandler">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Select Asset</label
          >

          <select
            class="form-select"
            aria-label="Default select example"
            name="transfer"
            required
            v-model="getWithdrawInput.asset"
          >
            <option value="">----</option>
            <option
              v-for="asset in getAssets.assets"
              :key="asset.id"
              :value="asset.id"
            >
              {{ asset.code }}
            </option>
          </select>
        </div>
        <div class="mb-3 text-center">
          <button
            type="submit"
            class="btn btn-primary"
            :class="{ disabled: !isInputFieldValids }"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AssetSelectionPage',
  data() {
    return {
      getWithdrawInput: {
        asset: ''
      }
    };
  },
  computed: {
    ...mapGetters(['getAssets']),
    isInputFieldValids() {
      return Object.values(this.getWithdrawInput).every((value) =>
        Boolean(value)
      );
    }
  },
  methods: {
    ...mapActions(['withdrawInputAc']),
    formSubmitHandler() {
      this.withdrawInputAc(this.getWithdrawInput).then(() => {
        const qparams = this.$route.query;
        this.$router.push({
          path: 'submit',
          query: qparams
        });
      });
    }
  }
};
</script>

<style scoped></style>
