<template>
  <router-view />
</template>

<script>
export default {
  name: 'depositLayout'
};
</script>

<style></style>
