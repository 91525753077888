<template>
  <div>
    <button
      class="btn btn-primary"
      @click="confirmWithdrawHandler"
      :class="{ disabled: !isInputFieldValids }"
    >
      confirm
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WithdrawConfirmPage',
  computed: {
    ...mapGetters(['getWithdrawInput']),
    isInputFieldValids() {
      return Object.values(this.getWithdrawInput).every((value) =>
        Boolean(value)
      );
    }
  },
  methods: {
    ...mapActions(['confirmWithdrawAc']),
    confirmWithdrawHandler() {
      const qparams = this.$route.query;
      const payload = this.$store.state.withdrawInput;
      payload.qparams = { ...qparams };

      this.confirmWithdrawAc(payload)
        .then(() => {
          this.$router.push({
            path: '/sep24/transaction/more_info',
            query: {
              id: qparams.transaction_id,
              initialLoad: true
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped></style>
