import assetService from '../services/asset';
import withdrawService from '../services/withdraw';

export default {
  withdrawInputAc({ commit }, payload) {
    commit('UPDATE_WITHDRAW_INPUT_VALUES', payload);
  },
  
  async fetchAssetsAc({ commit }) {
    try {
      const response = await assetService.fetchAll();
      commit('ASSETS', response.data);
      return Promise.resolve(response.data.assets);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async confirmWithdrawAc({ state }, payload) {
    try {
      const qparams = payload.qparams;
      delete payload.qparams;

      const response = await withdrawService.confirm(
        payload,
        qparams,
        state.token
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async moreInfoAc({ commit, state }, qparams) {
    try {
      const response = await withdrawService.moreInfo(qparams, state.token);
      commit('TRANSACTION', response.data.tx_json);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  tokenAc({ commit }, token) {
    commit('TOKEN', token);
  }
};
