export default {
  UPDATE_WITHDRAW_INPUT_VALUES(state, payload) {
    state.withdrawInput = { ...state.withdrawInput, ...payload };
  },
  ASSETS(state, payload) {
    state.assets = payload;
  },
  TOKEN(state, token) {
    localStorage.setItem('token', token);
    state.token = token;
  },
  TRANSACTION(state, payload) {
    state.transaction = payload;
  }
};
