<template>
  <div>Deposit Amount Page</div>
</template>

<script>
export default {
  name: 'DepositAmountPage'
};
</script>

<style scoped></style>
