<template>
  <div class="container mt-5">
    <div class="text-center mb-5">
      <a href="https://transcryptglobal.com/" target="_blank">
        <img alt="Match" src="@/assets/logo.png" class="img-fluid" />
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <form class="w-50" @submit.prevent="formSubmitHandler">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Amount</label
          >
          <input
            type="text"
            class="form-control"
            name="amount"
            required
            v-model="getWithdrawInput.amount"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Transaction Type</label
          >

          <select
            class="form-select"
            aria-label="Default select example"
            name="transfer"
            required
            v-model="getWithdrawInput.transactionType"
          >
            <option value="">----</option>
            <option value="Bank">Bank Transfer</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Bank Account</label
          >
          <input
            type="text"
            class="form-control"
            name="bankAccount"
            required
            v-model="getWithdrawInput.bankAccount"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Bank</label>
          <input
            type="text"
            class="form-control"
            name="bankName"
            required
            v-model="getWithdrawInput.bankName"
          />
        </div>
        <div class="mb-3 text-center">
          <button
            type="submit"
            class="btn btn-primary"
            :class="{ disabled: !isInputFieldValids }"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WithdrawAmountInputPage',
  data() {
    return {
      getWithdrawInput: {
        amount: '',
        transactionType: '',
        bankAccount: '',
        bankName: ''
      }
    };
  },
  computed: {
    isInputFieldValids() {
      return Object.values(this.getWithdrawInput).every((value) =>
        Boolean(value)
      );
    }
  },
  methods: {
    ...mapActions(['withdrawInputAc', 'fetchAssetsAc', 'tokenAc']),
    formSubmitHandler() {
      this.withdrawInputAc(this.getWithdrawInput).then(async () => {
        const qparams = this.$route.query;
        await this.tokenAc(qparams.token);
        delete qparams.token;
        this.$router.push({
          path: 'asset',
          query: qparams
        });
      });
    },
    async loadAssets() {
      try {
        await this.fetchAssetsAc();
      } catch (error) {
        alert(error.message);
      }
    }
  },
  async mounted() {
    this.loadAssets();
  }
};
</script>

<style scoped></style>
