import MoreInfo from '../pages/MoreInfo.vue';

// deposit pages
import DepositLayout from '../layouts/Deposit.vue';
import DepositAmount from '../pages/deposit/Amount.vue';
import DepositAssetSelection from '../pages/deposit/AssetSelection.vue';

// withdrawal pages
import WithdrawLayout from '../layouts/Withdraw.vue';
import WithdrawAmount from '../pages/withdraw/Amout.vue';
import WithdrawAssetSelection from '../pages/withdraw/AssetSelection.vue';
import WithdrawConfirm from '../pages/withdraw/Confirm.vue';

export default [
  {
    path: '/sep24/transactions/deposit',
    component: DepositLayout,
    children: [
      {
        path: 'webapp',
        name: 'DepositAsset',
        component: DepositAssetSelection
      },
      {
        path: 'submit',
        name: 'DepositAmount',
        component: DepositAmount
      }
    ]
  },
  {
    path: '/sep24/transaction/more_info',
    name: 'MoreInfo',
    component: MoreInfo
  },
  {
    path: '/sep24/transactions/withdraw',
    component: WithdrawLayout,
    children: [
      {
        path: 'webapp',
        name: 'WithdrawAmount',
        component: WithdrawAmount
      },
      {
        path: 'asset',
        name: 'WithdrawAsset',
        component: WithdrawAssetSelection
      },
      {
        path: 'submit',
        name: 'WithdrawConfirm',
        component: WithdrawConfirm
      }
    ]
  }
];
