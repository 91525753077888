<template>
  <div class="container">
    <div class="mb-4">
      <a href="https://transcryptglobal.com/" target="_blank">
        <!-- <img alt="Match" src="@/assets/logo.png" class="img-fluid" /> -->
      </a>
      <div
        class="mt-3 text-center"
        v-if="getTransaction && Object.keys(getTransaction).length"
      >
        <div class="mb-2 fs-6">
          <p class="text-muted m-0">Transaction Type</p>
          <h5>{{ getTransaction.kind }}</h5>
        </div>
        <div class="mb-2">
          <p class="text-muted m-0">Transaction ID</p>
          <h5>{{ getTransaction.id }}</h5>
        </div>
        <div class="mb-2">
          <p class="text-muted m-0">Amount Sent</p>
          <h5>
            {{ getTransaction.asset.code }}
            {{ Number(getTransaction.amount_in).toFixed(2) }}
          </h5>
        </div>
        <div class="mb-2">
          <p class="text-muted m-0">Fees</p>
          <h5>
            {{ getTransaction.asset.code }}
            {{ Number(getTransaction.amount_fee).toFixed(2) }}
          </h5>
        </div>
        <div class="mb-2">
          <p class="text-muted m-0">Amount Received</p>
          <h5>
            {{ getTransaction.asset.code }}
            {{ Number(getTransaction.amount_out).toFixed(2) }}
          </h5>
        </div>
        <div class="mb-2">
          <p class="text-muted m-0">Transaction Status</p>
          <h5>{{ getTransaction.status }}</h5>
        </div>
        <div class="mb-2">
          <p class="text-muted m-0">Started</p>
          <h5>{{ getTransaction.started_at }}</h5>
        </div>
        <div class="mb-2" v-if="getTransaction.completed_at">
          <p class="text-muted m-0">Completed</p>
          <h5>{{ getTransaction.completed_at || 'N/A' }}</h5>
        </div>
      </div>
      <div v-else>
        <h1>Loading...</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MoreInfoPage',
  data() {
    return {
      transaction: {}
    };
  },
  computed: {
    ...mapGetters(['getTransaction'])
  },
  methods: {
    ...mapActions(['moreInfoAc']),
    callback({ txJSON, onChangeCallback = '', transactionStatus, callback = '' }) {
      if (!onChangeCallback) onChangeCallback = '';
      let url = new URL(window.location);
      let currentStatus = transactionStatus;
      let isInitialLoad = url.searchParams.get('initialLoad');
      let lastStatusChange = url.searchParams.get('lastStatusChange');
      if (
        isInitialLoad ||
        (lastStatusChange && lastStatusChange !== currentStatus)
      ) {
        if (onChangeCallback.toLowerCase() === 'postmessage') {
          postMessageCallback({
            onChange: true
          });
        } else if (onChangeCallback && isInitialLoad) {
          urlCallback({
            onChange: true
          });
        }
      } else {
        updateURLForOnChangeStatus();
      }

      if (callback.toLowerCase() === 'postmessage') {
        postMessageCallback({
          onChange: false
        });
      } else if (callback) {
        urlCallback({
          onChange: false
        });
      }

      // Callback function to post the serialized transaction to the wallet.
      function postMessageCallback({ onChange }) {
        let targetWindow;

        if (window.opener) {
          targetWindow = window.opener;
        } else if (window.parent) {
          targetWindow = window.parent;
        } else {
          return;
        }

        targetWindow.postMessage(JSON.parse(txJSON), '*');
        if (onChange) {
          updateURLForOnChangeStatus();
        } else {
          updateURLWithCallbackSuccess();
        }
      }

      // Callback function to post the serialized transaction to the callback URL
      function urlCallback({ onChange }) {
        let url = onChange ? onChangeCallback : callback;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(txJSON)
        })
          .catch((e) => {
            console.error(`POST request to ${url} failed.`, e);
          })
          .then((response) => {
            if (!response.ok) {
              console.error(`POST request to ${url} failed.`, response);
              return;
            }
            if (onChange) {
              updateURLForOnChangeStatus();
            } else {
              updateURLWithCallbackSuccess();
            }
          });
      }

      function updateURLWithCallbackSuccess() {
        let url = new URL(window.location);
        url.searchParams.set('callback', 'success');
        if (isInitialLoad) {
          url.searchParams.delete('initialLoad');
        }
        window.history.replaceState({}, '', url);
      }

      function updateURLForOnChangeStatus() {
        let url = new URL(window.location);
        if (isInitialLoad) {
          url.searchParams.delete('initialLoad');
          window.history.replaceState({}, '', url);
        }
        if (onChangeCallback.toLowerCase() === 'postmessage') {
          url.searchParams.set('lastStatusChange', currentStatus);
          window.history.replaceState({}, '', url);
        }
      }
    }
  },
  async mounted() {
    const query = this.$route.query;
    await this.moreInfoAc(query);
    if (this.getTransaction && Object.keys(this.getTransaction).length) {
      const txStatus = this.getTransaction.status;
      const onChangeCallback = this.getTransaction.on_change_callback || '';
      const callback = 'callback';
      const txJSON = this.getTransaction;
      this.callback(txJSON, onChangeCallback, txStatus, callback);
    }
  }
};
</script>

<style scoped></style>
