<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
};
</script>

<style>
#app {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  text-align: center;
  color: #2c3e50;
  margin-top: 45px;
}
</style>
